import React, {CSSProperties} from "react";

interface RedDotTitleProps {
    title: string;
    style?: CSSProperties | undefined;
}

const RedDotTitle: React.FC<RedDotTitleProps> = (props) => {
    const {title, style} = props
    return <div style={style}>
        <div
            style={{
                display: 'inline-flex',
                alignItems: 'center',
                fontSize: '35px',
                lineHeight: '35px',
            }}
        >
            <div
                style={{
                    width: '22px',
                    height:'22px',
                    minWidth: '22px',
                    background: '#FD6865',
                    borderRadius: '50%',
                    display: 'inline-block', 
                }}
            >
            </div>
            <span style={{padding: '0 4px', color: 'white', fontSize: '24px'}}>{title}</span>
        </div>
    </div>
}
export default RedDotTitle