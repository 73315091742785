const chart2 = () => {
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 1)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        grid: {
            top: 40,
            left: 60,
            right: 60,
            bottom: 60,
        },
        xAxis: [
            {
                type: 'category',
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: 'rgba(35, 183, 229, 1)',
                    },
                },
                axisLabel: {
                    interval: 0,
                    rotate: -25,
                    textStyle: {
                        fontSize: '14px',
                    },
                },
                data: [
                    '核心园区',
                    '高新分园区',
                    '路南园区',
                    '开平（博玉）园区',
                    '迁安园区',
                    '曹妃甸园区',
                    '开平（蔚徕）园区',
                    '路北园区',
                    '玉田园区',
                    '丰南园区',
                ],
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '举办培训班场次',
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: 'rgba(35, 183, 229, 1)',
                    },
                },
                splitLine: { // 分隔线
                    show: false,
                },
                axisLabel: {
                    formatter: (value: number) => {
                        switch (true) {
                            case value >= 10000:
                                return value / 10000 + '万'
                            case value >= 1000:
                                return value / 1000 + '千'
                            default:
                                return value
                        }
                    },
                    textStyle: {
                        fontSize: '14px',
                    },
                },
                nameTextStyle:{
                    fontSize:'16px'
                }

            },
            {
                type: 'value',
                name: '培训人数',
                nameTextStyle:{
                    fontSize:'16px'
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: 'rgba(35, 183, 229, 1)',
                    },
                },
                title: {
                    textStyle: {
                      fontSize: 16 // 设置标题文本大小为16像素
                    }
                },
                splitLine: { // 分隔线
                    show: false,
                },
                axisLabel: {
                    formatter: (value: number) => {
                        switch (true) {
                            case value >= 10000:
                                return value / 10000 + '万'
                            case value >= 1000:
                                return value / 1000 + '千'
                            default:
                                return value
                        }
                    },
                    textStyle: {
                        fontSize: '14px',
                    },
                },
            },
        ],
        series: [
            {
                name: '举办培训班场次',
                type: 'bar',
                data: [
                    246,
                    22,
                    53,
                    1540,
                    488,
                    203,
                    42,
                    109,
                    7,
                    106,
                ],
                barWidth: 10,
                yAxisIndex: 0,
                itemStyle: {
                    borderRadius: [20],
                    color: 'rgba(35, 183, 229, 0.6)',
                }
            },
            {
                name: '培训人数',
                type: 'bar',
                data: [
                    1.6795,
                    0.0311,
                    0.1930,
                    2.3580,
                    2.3626,
                    0.3145,
                    0.1294,
                    0.1641,
                    0.0185,
                    0.1583
                ].map((it) => {
                    return (it * 10000).toFixed()
                }),
                barWidth: 10, // 柱状图宽度
                yAxisIndex: 1,
                itemStyle: {
                    borderRadius: [20],
                    color: 'rgba(35, 183, 229, 0.3)',
                },
            }
        ]
    };
};
export default chart2
