import * as echarts from 'echarts';

const chart7 = () => {
    const colorList = [
        {colorStart: '#488BFF', colorEnd: '#9abffd'},
        {colorStart: '#26CEBA', colorEnd: '#8efaed'},
        {colorStart: '#FFC069', colorEnd: '#fadfba'},
        {colorStart: '#FD6865', colorEnd: '#f8b2b1'},
    ];
    return {
        tooltip: {
            trigger: 'item',
            borderColor: 'rgba(35, 183, 229, 1)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
            formatter: (params: any) => {
                return `${params.name || '-'} ${params.value || '-'} 万元`
            },
        },

        series: [
            {
                name: '纳税总额',
                type: 'pie',
                radius: '90%',
                data: [
                    {name: '开平（博玉）园区', value: 23.11,},
                    {name: '高新分园区', value: 1599.98,},
                    {name: '核心园区', value: 1385.59,},
                    {name: '路南园区', value: 257.8,},
                    {name: '迁安园区', value: 690.65,},
                    {name: '曹妃甸园区', value: 666.52,},
                    {name: '路北园区', value: 56.2313,},
                    {name: '玉田园区', value: 0},
                    {name: '丰南园区', value: 284.14,},
                    {name: '开平（蔚徕）园区', value: 528.67906,},
                ].map((it: any, index) => {
                    const color = colorList[index % colorList.length]
                    return {...it, label: {color: color['colorStart']}}
                }),
                itemStyle: {
                    normal: {
                        color: (list: any) => {
                            const color = colorList[list.dataIndex % colorList.length]
                            return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                                {offset: 0, color: color['colorStart']},
                                {offset: 1, color: color['colorEnd']},
                            ])
                        }
                    }
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                label: {
                    position: 'outer',
                    alignTo: 'edge',
                    margin: 10,
                    textStyle: {
                        color: 'inherit', // 继承颜色
                        fontSize: '18px',
                    },
                    formatter: (params: any) => {
                        return `${params.name || '-'}`
                    }
                },
            }
        ]
    };
};
export default chart7
