import * as echarts from 'echarts';
import 'echarts-gl';
import HebeiTangshanGeoJSON from '../../../GeoJSON/hebei_tangshan.json'

const geoCoordMap: any = {
    '核心园区': [118.220, 39.52],
    '高新分园区': [118.19, 39.69],
    '路南园区': [118.188, 39.58],
    '开平（博玉）园区': [118.3, 39.75],
    '迁安园区': [118.696642, 40.006901],
    '曹妃甸园区': [118.474703, 39.058266],
    '开平（蔚徕）园区': [118.3, 39.620],
    '丰南园区': [118.100, 39.40],
    '玉田园区': [117.746919, 39.90677],
    '路北园区': [118.05, 39.640],
}

const data = [
    {name: '核心园区', value: 20,},
    {name: '高新分园区', value: 20,},
    {name: '路南园区', value: 20,},
    {name: '开平（博玉）园区', value: 40,},
    {name: '迁安园区', value: 20,},
    {name: '曹妃甸园区', value: 20,},
    {name: '开平（蔚徕）园区', value: 30,},
    {name: '丰南园区', value: 40,},
    {name: '玉田园区', value: 20,},
    {name: '路北园区', value: 20,},
]
const convertData = function (data: any) {
    const res = [];
    for (let i = 0; i < data.length; i++) {
        const geoCoord = geoCoordMap[data[i].name];
        if (geoCoord) {
            res.push({
                name: data[i].name,
                value: geoCoord.concat(data[i].value)
            });
        }
    }
    return res;
};

const hebeiTangshanMap = () => {
    // 地图数据来自 https://datav.aliyun.com/portal/school/atlas/area_selector
    // DataV GeoAtlas
    const buildingsGeoJSON: any = HebeiTangshanGeoJSON
    echarts.registerMap('hebei_tangshan', buildingsGeoJSON);

    return {
        animation: false,
        tooltip: {
            trigger: 'item',
        },
        geo: {
            roam: true,
            scaleLimit: {
                min: 0.8,
                max: 6,
            },
            zoom:0.8,
            center: [118.3, 39.65],
            // center: [118.26, 39.69],
            map: 'hebei_tangshan',
            label: {
                show: false,
                color: 'rgba(255,255,255,0)',
            },
            itemStyle: {
                color: 'rgba(35, 183, 229, 0.3)',
                areaColor: 'rgba(35, 183, 229, 0.3)',
                borderColor: 'rgba(35, 183, 229, 1)',
                backgroundColor: 'rgba(35, 183, 229, 0.6)',
                borderWidth: 1,
                shadowColor: 'rgba(0, 0, 0, 0.3)',
                shadowBlur: 3,
                shadowOffsetX: 3,
                shadowOffsetY: 5,
            },
            emphasis: {
                label: {
                    show: false,
                    color: 'rgba(255,255,255,0)',
                },
                itemStyle: {
                    color: 'rgba(35, 183, 229, 0.3)',
                    areaColor: 'rgba(35, 183, 229, 0.3)',
                    borderColor: 'rgba(35, 183, 229, 1)',
                    backgroundColor: 'rgba(35, 183, 229, 0.6)',
                    borderWidth: 1,
                    shadowColor: 'rgba(0, 0, 0, 0.8)',
                    shadowBlur: 6,
                    shadowOffsetX: 6,
                    shadowOffsetY: 8,
                }
            },
            tooltip: {
                show: false,
            },
        },
        series: [
            {
                type: 'lines',
                coordinateSystem: 'geo',
                effect: {
                    show: true,
                    color: 'rgba(245,243,179,0.8)',
                    period: 3,
                    symbolSize: 4,
                    trailLength: 0.6,
                    roundTrip: true,
                },
                lineStyle: {
                    color: 'rgba(35, 183, 229, 0.3)',
                    width: 1,
                    curveness: .3,
                },
                data: Object.entries(geoCoordMap)
                    .filter(([key]) => key !== "核心园区")
                    .map((it) => {
                        return {
                            coords: [
                                geoCoordMap['核心园区'], it[1],
                            ],
                        }
                    }),
                tooltip: {
                    show: false,
                },
            },
            {
                name: '',
                type: 'scatter',
                coordinateSystem: 'geo',
                data: convertData(data),
                symbolSize: 10,
                encode: {
                    value: 2
                },
                itemStyle: {
                    color: 'rgba(35, 183, 229, 1)'
                },
                emphasis: {
                    itemStyle: {
                        color: 'rgba(35, 183, 229, 1)'
                    }
                },
                label: {
                    show: true,
                    fontSize: '14px',
                    color: 'rgba(255,255,255,1)',
                    formatter: '{b}',
                    position: 'right',
                    borderColor: 'red',
                    borderWidth: 0,
                },
                tooltip: {
                    show: true,
                    borderColor: 'rgba(35, 183, 229, 1)',
                    backgroundColor: 'rgba(35, 183, 229, 1)',
                    borderWidth: 1,
                    textStyle: {
                        color: '#FFF',
                        fontSize: '18px',
                    },
                    formatter: function (params: any) {
                        return `${params.data.name || '-'}`
                    }
                },
                rippleEffect: {
                    brushType: 'stroke'
                },
                zlevel: 1,
            },
        ]
    };
};
export default hebeiTangshanMap
