import React, {useEffect, useRef, useState} from 'react';
import {Card, Modal, Space} from 'antd';
import * as echarts from 'echarts';
// import {ScrollBoard} from '@jiaminghi/data-view-react'
import style from './Page1.module.scss';
import PageBox from "../../components/PageBox";
import Headline from "../../components/Headline";
import TileBackground from "../../components/TileBackground";
import RedDotTitle from "../../components/RedDotTitle";
import Decoration from '../../components/Decoration';
import PlanetaryEngine from "../../components/decoration/planetary_engine";
import HorizontalTimeline from "./components/horizontal_timeline";
import chart1 from "./components/chart1";
import chart2 from "./components/chart2";
import chart3 from "./components/chart3";
import hebeiTangshanMap from "./components/hebei_tangshan_map";
// import chart4 from "./components/chart4";
import chart6 from "./components/chart6";
import chart7 from "./components/chart7";
import chart8 from './components/chart8';
import chart5 from './components/chart5';

interface Page1Props {
    width: number;
    height: number;
}

const Page1: React.FC<Page1Props> = (props) => {
    const {width, height} = props

    const [appBaseURL, setAppBaseURL] = useState('');
    const [isModalOpen, setIsModalOpen] = useState([false, '']);
    const hideModal = () => {
        setIsModalOpen([false, '']);
    };

    const _Chart1: any = useRef();
    const domRefChart1: any = useRef();

    const _Chart2: any = useRef();
    const domRefChart2: any = useRef();

    const _Chart3: any = useRef();
    const domRefChart3: any = useRef();

    const _Chart7: any = useRef();
    const domRefChart7: any = useRef();

    const _Chart4: any = useRef();
    const domRefChart4: any = useRef();

    const _Chart6: any = useRef();
    const domRefChart6: any = useRef();

    const _Chart8: any = useRef();
    const domRefChart8: any = useRef();

    const _Chart5: any = useRef();
    const domRefChart5: any = useRef();

    const chartsInit = () => {
        if (_Chart1.current) _Chart1.current.dispose()
        _Chart1.current = echarts.init(domRefChart1.current);
        _Chart1.current.setOption(chart1());

        if (_Chart2.current) _Chart2.current.dispose()
        _Chart2.current = echarts.init(domRefChart2.current);
        _Chart2.current.setOption(chart2());

        if (_Chart3.current) _Chart3.current.dispose()
        _Chart3.current = echarts.init(domRefChart3.current);
        _Chart3.current.setOption(chart3());

        if (_Chart7.current) _Chart7.current.dispose()
        _Chart7.current = echarts.init(domRefChart7.current);
        _Chart7.current.setOption(chart7());

        if (_Chart4.current) _Chart4.current.dispose()
        _Chart4.current = echarts.init(domRefChart4.current);
        _Chart4.current.setOption(hebeiTangshanMap());
        _Chart4.current.on('click', (params: any) => {
            if (params.componentSubType === 'scatter') {
                setIsModalOpen([true, params.data.name])
            }
        })

        if (_Chart6.current) _Chart6.current.dispose()
        _Chart6.current = echarts.init(domRefChart6.current);
        _Chart6.current.setOption(chart6());

        if (_Chart8.current) _Chart8.current.dispose()
        _Chart8.current = echarts.init(domRefChart8.current);
        _Chart8.current.setOption(chart8());

        if (_Chart5.current) _Chart5.current.dispose()
        _Chart5.current = echarts.init(domRefChart5.current);
        _Chart5.current.setOption(chart5());
    };
    useEffect(() => {
        chartsInit();
        setAppBaseURL((process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PRO) || '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TileBackground/>
            <PageBox id={'Page1'} width={width} height={height}>
                <div style={{position: 'absolute', left: 0, top: 0,}} className={style.background}></div>

                <div style={{position: 'absolute', left: '1200px', top: '800px', opacity: .3,}}>
                    <div className={[style.circle, style.animation_4].join(' ')}>
                        <div className={['accretion_disk'].join(' ')}>
                            <div className={['core'].join(' ')}/>
                        </div>
                    </div>
                </div>
                <div style={{position: 'absolute', left: '640px', top: '780px', opacity: .2,}}>
                    <div className={style.corrugation} style={{width: '80px', height: '80px',}}>
                        <div className={[style.circle, 'circle'].join(' ')}/>
                        <div className={[style.circle1, 'circle1'].join(' ')}/>
                        <div className={[style.circle2, 'circle2'].join(' ')}/>
                        <div className={[style.circle3, 'circle3'].join(' ')}/>
                    </div>
                </div>
                <div style={{
                    position: 'absolute', left: '1200px', top: '665px',
                    width: '400px', height: '400px',
                }}>
                    <Decoration opacity={0.04}/>
                </div>
                <div style={{
                    position: 'absolute', left: '480px', top: '180px',
                    width: '200px', height: '200px',
                }}>
                    <Decoration opacity={0.06}/>
                </div>

                <Headline title={'唐山市人力资源服务产业园数据门户'}/>

                <div style={{
                    width: '80%',
                    position: 'absolute', left: '150px', top: '60px',
                }}>
                    <PlanetaryEngine
                        style={{transform: `scale(0.5) translate(-50%, -50%)`, opacity: 1}}/>
                </div>
                <div style={{
                    width: '80%',
                    position: 'absolute', left: '605px', top: '60px',
                }}>
                    <PlanetaryEngine
                        style={{transform: `scale(0.5) translate(-50%, -50%)`, opacity: 1}}/>
                </div>
                <div style={{
                    width: '80%',
                    position: 'absolute', left: '1060px', top: '60px',
                }}>
                    <PlanetaryEngine
                        style={{transform: `scale(0.5) translate(-50%, -50%)`, opacity: 1}}/>
                </div>
                <div style={{
                    width: '80%',
                    position: 'absolute', left: '1516px', top: '60px',
                }}>
                    <PlanetaryEngine
                        style={{transform: `scale(0.5) translate(-50%, -50%)`, opacity: 1}}/>
                </div>
                <div style={{
                    position: 'absolute', left: '0', top: '110px',
                    width: 'calc(100% - 160px)',
                    boxSizing: 'border-box',
                    marginLeft: '80px',
                    marginRight: '80px',
                }}>
                    <Space style={{height: '110px', display: 'flex', flexFlow: 'row', overflow: 'hidden',}}
                           styles={{item: {flex: 1,}}}
                           size={60}
                    >
                        {[
                            {
                                title: <>营业收入</>, value: '39.08 亿元',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: <>纳税额</>, value: '5492.7 万元',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: <>园区面积</>, value: '91335 ㎡',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                            {
                                title: <>入驻企业</>, value: '162 家',
                                gradient: 'linear-gradient(45deg, rgba(35, 183, 229, 0.6), rgba(23, 95, 190, 0.6))',
                            },
                        ].map((it, index) => (
                            <Card
                                key={index}
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    background: 'transparent',
                                    color: '#FCE996',
                                }}
                                bodyStyle={{padding: '8px'}}
                                bordered={false}
                            >
                                <div style={{fontSize: '38px', fontWeight: 'bold'}}>{it.value}</div>
                                <div style={{fontSize: '16px',color:'#fff'}}>{it.title}</div>
                            </Card>
                        ))}
                    </Space>
                </div>

                <div style={{position: 'absolute', left: '20px', top: '240px',}}>
                    <RedDotTitle title={'营业收入'}/>
                </div>
                <div style={{position: 'absolute', left: '60px', top: '280px',}}>
                    <div ref={domRefChart3} style={{width: '440px', height: '160px',}}/>
                </div>

                <div style={{position: 'absolute', left: '20px', top: '460px',}}>
                    <RedDotTitle title={'纳税总额'}/>
                </div>
                <div style={{position: 'absolute', left: '60px', top: '500px',}}>
                    <div ref={domRefChart7} style={{width: '440px', height: '160px',}}/>
                </div>

                <div style={{position: 'absolute', left: '20px', top: '720px',}}>
                    <div ref={domRefChart5} style={{width: '260px', height: '340px',}}/>
                </div>
                <div style={{position: 'absolute', left: '20px', top: '680px',}}>
                    <RedDotTitle title={'园区面积'}/>
                </div>
                <div style={{position: 'absolute', left: '300px', top: '720px',}}>
                    <div ref={domRefChart8} style={{width: '260px', height: '340px',}}/>
                </div>
                <div style={{position: 'absolute', left: '340px', top: '680px',}}>
                    <RedDotTitle title={'入驻企业'}/>
                </div>

                {/* <div style={{position: 'absolute', left: '580px', top: '840px',}}>
                    <RedDotTitle title={'招聘服务'}/>
                </div>
                <div style={{position: 'absolute', left: '580px', top: '880px',}}>
                    <ScrollBoard config={chart4()} style={{width: '700px', height: '160px'}}/>
                </div> */}

                <div style={{
                    width: '520px', height: '100px',
                    position: 'absolute', left: '550px', top: '880px',
                }}>
                    <HorizontalTimeline/>
                </div>

                <div style={{position: 'absolute', left: '1300px', top: '270px',}}>
                    <RedDotTitle title={'培训服务（培训场次和培训人数）'}/>
                </div>
                <div style={{position: 'absolute', left: '1320px', top: '310px',}}>
                    <div ref={domRefChart2} style={{width: '580px', height: '320px',}}/>
                </div>

                <div style={{position: 'absolute', left: '1300px', top: '680px',}}>
                    <RedDotTitle title={'帮扶就业和流动人次'}/>
                </div>
                <div style={{position: 'absolute', left: '1300px', top: '720px',}}>
                    <div ref={domRefChart6} style={{width: '280px', height: '340px',}}/>
                </div>

                <div style={{position: 'absolute', left: '1550px', top: '680px',}}>
                    <RedDotTitle title={'服务用人单位'}/>
                </div>
                <div style={{position: 'absolute', left: '1550px', top: '720px',}}>
                    <div ref={domRefChart1} style={{width: '380px', height: '340px',}}/>
                </div>

                <div style={{
                    width: '800px', height: '800px',
                    position: 'absolute', left: '500px', top: '100px',
                }}>
                    <div style={{
                        position: 'absolute', left: '0', top: '0',
                        mask: 'radial-gradient(#000 60%, transparent 68%)',
                    }}>
                        <div ref={domRefChart4} style={{width: '900px', height: '900px',}}/>
                    </div>
                    {/* <div className={style.ring_diffuse} style={{width: '660px', height: '660px',}}/> */}
                    {/* <div className={style.ring} style={{width: '600px', height: '600px',}}>
                        <div className={[style.circle, style.animation_1].join(' ')}/>
                        <div className={[style.circle, style.animation_2].join(' ')}>
                            <div className={['accretion_disk'].join(' ')}>
                                <div className={['core'].join(' ')}/>
                            </div>
                        </div>
                        <div className={[style.circle, style.animation_3].join(' ')}>
                            <div className={['core'].join(' ')}>
                                <div className={['quark'].join(' ')}/>
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* <div style={{position: 'absolute', left: '1150px', top: '380px',}}>
                    <div className={style.corrugation} style={{width: '200px', height: '200px'}}>
                        <div className={[style.circle, 'circle'].join(' ')}
                             onClick={() => {
                                 console.log('土星')
                                 _Chart4.current.setOption(hebeiTangshanMap(), true);
                             }}/>
                        <div className={[style.circle1, 'circle1'].join(' ')}/>
                        <div className={[style.circle2, 'circle2'].join(' ')}/>
                        <div className={[style.circle3, 'circle3'].join(' ')}/>
                    </div>
                </div> */}

                <div className={style.stars_space} style={{
                    position: 'absolute', left: '860px', top: '230px',
                    width: '400px', height: '400px',
                }}>
                    {/* <div className={[style.star, style.star_a].join(' ')}></div> */}
                    {/*<div className={[style.star, style.star_b].join(' ')}></div>*/}
                    {/* <div className={[style.star, style.star_c].join(' ')}></div>
                    <div className={[style.star, style.star_d].join(' ')}></div> */}
                    {/*<div className={[style.star, style.star_e].join(' ')}></div>*/}
                    {/* <div className={[style.star, style.star_f].join(' ')}></div> */}
                    {/*<div className={[style.star, style.star_g].join(' ')}></div>*/}
                    {/* <div className={[style.star, style.star_h].join(' ')}></div> */}
                </div>
            </PageBox>
            <Modal
                title={isModalOpen[1]}
                open={isModalOpen[0] as boolean}
                onOk={hideModal}
                onCancel={hideModal}
                footer={[]}
                width={'65%'}
                styles={{
                    body: {
                        aspectRatio: 1280 / 840,
                    },
                    header: {
                        backgroundColor: '#0000',
                    },
                    content: {
                        background: 'linear-gradient(135deg, #c850c0, #4158d0)'
                    }
                }}
                centered
            >
                <iframe
                    title={`${isModalOpen[1]}`}
                    width="100%"
                    height="100%"
                    style={{overflow: 'hidden', border: 0}}
                    src={`${appBaseURL}/page_drill_1?region_name=${isModalOpen[1]}`}
                />
            </Modal>
        </>
    );
}

export default Page1;
