const chart1 = () => {
    const data = [
        // {"name": "核心园区", "服务人次": "11.9558", "服务用人单位": "1.2287"},
        // {"name": "高新分园区", "服务人次": "0.7168", "服务用人单位": "0.0681"},
        // {"name": "路南园区", "服务人次": "1.6321", "服务用人单位": "0.6241"},
        // {"name": "开平（博玉）园区", "服务人次": "4.3267", "服务用人单位": "0.2339"},
        // {"name": "迁安园区", "服务人次": "0.8394", "服务用人单位": "0.0376"},
        // {"name": "曹妃甸园区", "服务人次": "3.51", "服务用人单位": "0.4702"},
        // {"name": "开平（蔚徕）园区", "服务人次": "5.2464", "服务用人单位": "0.1675"},
        // {"name": "路北园区", "服务人次": "0.441", "服务用人单位": "0.2234"},
        // {"name": "玉田园区", "服务人次": "0.0222", "服务用人单位": "0.0118"},
        // {"name": "丰南园区", "服务人次": "5.5636", "服务用人单位": "0.0897"},
        {"name": "核心园区",  "服务用人单位": "1.1564"},
        {"name": "高新分园区", "服务用人单位": "0.0037"},
        {"name": "路南园区", "服务用人单位": "0.5022"},
        {"name": "开平（博玉）园区", "服务用人单位": "0.2879"},
        {"name": "迁安园区", "服务用人单位": "0.0226"},
        {"name": "曹妃甸园区", "服务用人单位": "0.3953"},
        {"name": "开平（蔚徕）园区", "服务用人单位": "0.2885"},
        {"name": "路北园区", "服务用人单位": "0.0131"},
        {"name": "玉田园区", "服务用人单位": "0.0312"},
        {"name": "丰南园区", "服务用人单位": "0.0532"},
    ]
    return {
        tooltip: {
            trigger: 'axis',
            borderColor: 'rgba(35, 183, 229, 1)',
            backgroundColor: 'rgba(35, 183, 229, 0.6)',
            borderWidth: 1,
            textStyle: {
                color: '#FFF',
                fontSize: '18px',
            },
        },
        grid: {
            top: 40,
            left: 80,
            right: 60,
            bottom: 70,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: data.map((it) => it.name),
            axisLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(35, 183, 229, 1)',
                },
            },
            axisLabel: {
                interval: 0,
                rotate: -30,
                textStyle: {
                    fontSize: '12px',
                },
            },
        },
        yAxis: {
            type: 'value',
            axisLine: {
                show: true,
                lineStyle: {
                    color: 'rgba(35, 183, 229, 1)',
                },
            },
            splitLine: { // 分隔线
                show: false,
            },
            axisLabel: {
                formatter: (value: number) => {
                    switch (true) {
                        case value >= 10000:
                            return value / 10000 + '万'
                        case value >= 1000:
                            return value / 1000 + '千'
                        default:
                            return value
                    }
                },
                textStyle: {
                    fontSize: '14px',
                },
            },
        },
        series: [
            // {
            //     data: data.map((it) => (Number(it['服务人次']) * 10000).toFixed()),
            //     symbol: 'circle',
            //     symbolSize: 8,
            //     type: 'line',
            //     emphasis: {
            //         focus: 'series'
            //     },
            //     lineStyle: {
            //         color: 'rgba(35, 183, 229, 0.6)',
            //         width: 1,
            //     },
            //     itemStyle: {
            //         borderWidth: 1,
            //         normal: {
            //             borderColor: 'rgb(50,202,250,.4)',
            //             borderWidth: 2,
            //             color: 'rgb(29,146,182)',//拐点颜色
            //         },
            //         emphasis: {
            //             color: 'rgba(50,202,250)'//hover拐点颜色定义
            //         }
            //     },
            //     markPoint: {
            //         data: [
            //             // {type: 'max', name: 'Max'},
            //         ],
            //         label: {
            //             color: 'rgba(255,255,255,0.8)',
            //             fontSize: 10
            //         },
            //         itemStyle: {
            //             color: 'rgba(240,80,80,0.5)',
            //         },
            //         symbolOffset: ['0', '-10%'],
            //     },
            // },
            {
                data: data.map((it) => (Number(it['服务用人单位']) * 10000).toFixed()),
                symbol: 'circle',
                symbolSize: 8,
                type: 'line',
                emphasis: {
                    focus: 'series'
                },
                lineStyle: {
                    color: 'rgba(35, 183, 229, 0.6)',
                    width: 1,
                },
                itemStyle: {
                    borderWidth: 1,
                    normal: {
                        borderColor: 'rgb(50,202,250,.4)',
                        borderWidth: 2,
                        color: 'rgb(29,146,182)',//拐点颜色
                    },
                    emphasis: {
                        color: 'rgba(50,202,250)'//hover拐点颜色定义
                    }
                },
                markPoint: {
                    data: [
                        // {type: 'max', name: 'Max'},
                    ],
                    label: {
                        color: 'rgba(255,255,255,0.8)',
                        fontSize: 10
                    },
                    itemStyle: {
                        color: 'rgba(240,80,80,0.5)',
                    },
                    symbolOffset: ['0', '-10%'],
                },
            }
        ]
    };
};
export default chart1
